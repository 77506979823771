@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500&display=swap');

*, *:after, *::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

* {
  font-family: 'Red Hat Display', sans-serif;
}

.hero-bg {
  background-image: url("https://tse4.mm.bing.net/th?id=OIP.yg6hRhPgFfcgljqagcZEzgHaFg&pid=Api");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0,0,0,.8);
  background-blend-mode: darken;
  background-position: 50% 50%;
}

.hero h1 {
  text-align: center;
  color: white;
  font-weight: 325;
  padding: 35px;
  font-size: 60px;
}

.hero-p  {
  width: 70%;
  margin: 0 auto;
  display: block;
  color:white;
  font-weight: 150;
  font-size: 23px;
  padding: 15px;
  margin-bottom: 60px;
}

.hero-img{
  width: clamp(20%, 4vw, 15%);
  margin: 0 auto;
  display: block;
  
  width: 12%; 
  margin-top: 80px;
  margin-bottom: 40px;
}

.image-2 {
  height: 350px;
  width: 350px;
  border-radius: 10px;
}

.p2 {
  text-align: center;
  h2 {
    font-weight: 400;
    font-size: 45px;
    margin: 50px;
    margin-bottom: 70px;
  }
  h3 {
    font-weight: 400;
    font-size: 37px;
    padding: 30px;
  }
  p {
    margin-bottom: 60px;
    width: 76%; 
    margin: 0 auto;
    display:block;
    margin-bottom: 60px;
    font-weight: 300;
    font-size: 25px
  }
}

.p3-header {
  display: flex;
  justify-content: center;
  h2 {
      margin-top: 100px;
      margin-bottom: 60px;
      font-size: 45px;
      font-weight: 400;
    }
}

.p3-list {
  display: flex;
  justify-content: space-around;
  .p3-table {
    //max-width: 25%;
    background-color: rgba(255, 192, 203, 0.698);
    border-radius: 10px;
    margin: 10px;
    text-align: center;
    padding: 25px;
    margin-bottom: 60px;
    h3 {
      font-weight: 400;
      font-size: 35px;
      margin-bottom: 40px;
    }
    p {
      font-size: 25px;
      font-weight: 300;
    }
  }
}

.page-4 {
  
  justify-content: center;
  width: 100%;
  
  h2 {
    font-size: 45px;
    font-weight: 400;
    margin-bottom: 30px;
    text-align: center;
  }
  ul {
    
    li {
      
      font-weight: 200;
      padding: 30px;
      font-size: 25px;
    }
  }
}

.p5 {
  h2 {
   text-align: center;
   padding: 40px;  
   margin-top: 30px;
   font-size: 45px;
   font-weight: 400;
 }
 ul {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
 }
 li {
  background-color: rgba(255, 192, 203, 0.698);
  list-style-type: none;
  border-radius: 15px;
  padding: 20px;
  //width: 40%;
  margin: 10px;
  
  h3 {
    font-weight: 300;
    font-size: 30px;
    padding: 15px;
  }
  p {
    font-weight: 150;
    font-size: 18px;
    padding: 15px;
  }
 }
}

.p6-list {
  //margin-right: 60px;
  //display: flex;
  justify-content: space-evenly;
  text-align: center;
  //width: 20%;
  margin-bottom: 60px;
   img {
    height: 300px;
    width: 300px;
    border-radius: 10px;
  }
  h3 {
    font-weight: 300;
    padding: 25px;
    font-size: 35px;
  }
  p {
    font-size: 20px;
    font-weight: 200;
  }
}

.p6 h2 {
  text-align: center;
  margin-top: 20px;
  padding: 40px;
  font-size: 50px;
  font-weight: 400;
}

.bottom-bg {
  //width: 100%;
  //margin: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  background-image: url("https://cdn-staging.gamma.app/cdn-cgi/image/quality=80,fit=scale-down,onerror=redirect,width=2400/QWjS6fBfpt/7c4bcd24cd8940788fd9fb155d789d7f/original/mesh-noise-gradient.png");
  //padding: 20px;
  background-size: cover;
  background-repeat: no-repeat;
}

.p7-list {
  // display: flex;
  // flex-direction: row;
  li {
    //justify-content: space-between;
    list-style-type: none;
    padding: 15px;
    //width: 40%;
    margin: 0 auto;
  }
}

.p8, .p7 {
  margin-top: 60px;
  margin-bottom: 60px;
  .container {
    width: 90%;
    //margin: 20px;
    background-color: white;
    padding: 40px;
    border-radius: 15px;
    h2 {
      text-align: center;
      padding: 40px;
      font-size: 40px;
      font-weight: 400;
      margin-bottom: 30px;
    }
    h3 {
      font-weight: 400;
    }
    p {
      font-size: 20px;
      font-weight: 200;
    }
  }
}
